function Row ({label, info}) {
	return <tr>
		<td>{label}:</td>
		<td>{info}</td>
	</tr>
}

export default function Response ({data}) {
	
	return <table>
		<tbody>
			<Row label='Razão Social' info={data.razao_social} />
			<Row label='Situação Cadastral' info={data.estabelecimento.situacao_cadastral} />
			<Row label='Inscrição Estadual' info={data.estabelecimento.inscricoes_estaduais[0].inscricao_estadual} />
			<Row label='Situação I.E.' info={data.estabelecimento.inscricoes_estaduais[0].ativo ? 'Ativo' : 'Inativo'} />
		</tbody>
	</table>

}