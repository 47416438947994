function Row ({data, row}) {
	return <tr>
		<td>{row}:</td>
		<td>{data[row]}</td>
	</tr>
}

export default function Response ({data}) {
	
	return <table>
		<tbody>
			<Row data={data} row='bairro' />
			<Row data={data} row='cep' />
			<Row data={data} row='complemento' />
			<Row data={data} row='ddd' />
			<Row data={data} row='gia' />
			<Row data={data} row='ibge' />
			<Row data={data} row='localidade' />
			<Row data={data} row='logradouro' />
			<Row data={data} row='siafi' />
			<Row data={data} row='uf' />
		</tbody>
	</table>

}