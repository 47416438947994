import ConsultaCEP from './ConsultaCEP'
import ConsultaCNPJ from './ConsultaCNPJ'

function App() {
  return <>
    <ConsultaCEP />
    <ConsultaCNPJ />
  </>
}

export default App
