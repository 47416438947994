import { useState } from 'react'
import Response from './Response'

async function fetch_data (value) {
	const url = `https://publica.cnpj.ws/cnpj/${value}`
	const response = await fetch(url)
	const data = await response.json()
	return data
}

export default function ConsultaCNPJ () {

	const [value, setValue] = useState('')
	const [info, setInfo] = useState(null)

	const handleClick = async () => {
		const data = await fetch_data(value)
		console.log(data)
		setInfo(data)
	}

	return <>
		<h1>Consulta CNPJ</h1>

		<input value={value} onChange={e => setValue(e.target.value.replace(/\D+/g, ''))} type='text' placeholder='CNPJ' />
		<input type='button' value='Consultar' onClick={handleClick} />
		<input type='button' value='Limpar' onClick={() => {
			setValue('')
			setInfo(null)
		}} />

		{info !== null && <Response data={info} />}
	</>

}